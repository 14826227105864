import { mapState } from 'vuex'

export default {
  name: 'MetricsMixin',
  computed: {
    ...mapState('landings', {
      preparedHead: (state) => state.preparedHead,
      domainData: (state) => state.currentDomainData,
    }),
    meta() {
      return this.preparedHead.meta.concat([
        {
          hid: 'description',
          name: 'description',
          content: this.$getTextSetting('description'),
        },
      ])
    },
    favicon() {
      switch (this.$route.path) {
        case '/coding/blogging': {
          return 'blogging.ico'
        }
        case '/coding/gamedesign': {
          return 'gm.ico'
        }
        case '/coding/bootcamp': {
          return 'bootcamp.ico'
        }
        case '/coding/design': {
          return 'design.ico'
        }
        case '/coding/pc': {
          return 'kg.ico'
        }
        case '/coding/junior': {
          return 'logics.ico'
        }
        case '/coding/pytpro': {
          return 'ppro.ico'
        }
        case '/coding/pytstart': {
          return 'pstart.ico'
        }
        case '/coding/creative': {
          return 'vp.ico'
        }
        case '/coding/websites': {
          return 'sc.ico'
        }
        case '/coding/camp': {
          return 'bootcamp.ico'
        }
        default:
          return 'main.ico'
      }
    },
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })

    return {
      ...this.preparedHead,
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
        dir: this.$direction(),
      },
      title: this.$getTextSetting('title'),
      meta: this.meta,
      link: [
        { rel: 'icon', type: 'image/x-icon', href: `/${this.favicon}` },
        {
          rel: 'canonical',
          href: 'https://' + this.domainData.siteUrl + this.$route.path,
        },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript'],
    }
  },
}
